export default {
  comphealth: [
    {
      name: 'physician',
      icon: 'profession-physician',
      text: 'Physician',
      value: {
        divisionCode: 'CHS',
        group: 'physician',
        url: '/register/profile/physician',
      },
    },
    {
      name: 'nurse',
      icon: 'profession-nurse',
      text: 'NP, PA, CRNA',
      value: {
        divisionCode: 'CHA',
        group: 'advanced-practice',
        url: '/register/profile/advanced-practice',
      },
    },
    {
      name: 'therapist',
      icon: 'profession-therapist',
      text: 'Therapy',
      value: {
        divisionCode: 'CHA',
        group: 'therapy',
        url: '/register/profile/therapy',
      },
    },
    {
      name: 'lab',
      icon: 'profession-lab',
      text: 'Medical Lab',
      value: {
        divisionCode: 'CHA',
        group: 'laboratory-professionals',
        url: '/register/profile/laboratory-professionals',
      },
    },
    {
      name: 'admin',
      icon: 'profession-admin',
      text: 'Healthcare Management',
      value: {
        group: 'admin',
        url: '/register/non-portal',
      },
    },
    {
      name: 'imaging',
      icon: 'profession-imaging',
      text: 'Radiology & Imaging',
      value: {
        group: 'imaging',
        url: '/register/non-portal',
      },
    },
    {
      name: 'pharmacy',
      icon: 'profession-pharmacy',
      text: 'Pharmacy',
      value: {
        group: 'pharmacy',
        url: '/register/non-portal',
      },
    },
    {
      name: 'employers',
      icon: 'profession-employers',
      text: 'Employers',
      value: {
        url: '/register/employers',
      },
    },
  ],
  weatherby: [
    {
      name: 'physician',
      icon: 'profession-physician',
      text: 'Physician',
      value: {
        divisionCode: 'WBY',
        group: 'physician',
        url: '/register/profile/physician',
      },
    },
    {
      name: 'nurse-practitioner',
      icon: 'profession-np',
      text: 'Nurse Practitioner',
      value: {
        divisionCode: 'WMS',
        group: 'np',
        url: '/register/profile/np',
      },
    },
    {
      name: 'physician-assistant',
      icon: 'profession-pa',
      text: 'Physician Assistant',
      value: {
        divisionCode: 'WMS',
        group: 'pa',
        url: '/register/profile/pa',
      },
    },
    {
      name: 'crna',
      icon: 'profession-crna',
      text: 'CRNA',
      value: {
        divisionCode: 'WMS',
        group: 'crna',
        url: '/register/profile/crna',
      },
    },
    {
      name: 'employers',
      icon: 'profession-employers',
      text: 'Employers',
      value: {
        url: '/register/employers',
      },
    },
  ],
  gmedical: [
    {
      name: 'physician',
      icon: 'profession-physician',
      text: 'Physician',
      value: {
        group: 'physician',
        url: '/register/profile/physician',
      },
    },
    {
      name: 'employers',
      text: 'Employers',
      icon: 'profession-employers',
      value: {
        url: '/register/employers',
      },
    },
  ],
}
