
import { mapGetters, mapActions } from 'vuex'
import LogRocket from 'logrocket'
import Link from '@/components/ui/Link/Link.vue'
import UIFormMessage from '@/components/ui/forms/UIFormMessage/UIFormMessage.vue'
import UITextfield from '@/components/ui/forms/UITextfield/UITextfield.vue'
import UISelectfield from '@/components/ui/forms/UISelectfield/UISelectfield.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import { Form as VeeForm } from 'vee-validate'
import replaceLast from '@/utils/replaceLast'

export default {
  name: 'RegisterProfile',

  components: {
    'app-link': Link,
    UIFormMessage,
    UITextfield,
    UISelectfield,
    PDEButton,
    PDEHeading,
    VeeForm,
  },

  props: {
    profession: {
      type: String,
      default: '',
    },
  },

  data: () => {
    return {
      formMessage: '',
      formMessageType: '',
      showModal: false,
      specialty: '',
      submitDisabled: true,
    }
  },

  computed: {
    ...mapGetters('identity', ['specialtiesList', 'getRegisterData']),
    ...mapGetters('analytics', ['getWebTracking']),
    ...mapGetters('system', ['isOnline']),

    specialtiesAreLoading() {
      return Object.keys(this.specialtiesList).length === 0
    },

    options() {
      return this.specialtiesList[this.profession] || []
    },

    showNpiField() {
      return [
        'advanced-practice',
        'crna',
        'np',
        'pa',
        'physician',
      ].includes(this.profession)
    },

    npiRequired() {
      return ![
        'advanced-practice',
        'crna',
        'np',
        'pa',
      ].includes(this.profession)
    },

    isCRNA() {
      return this.profession === 'crna'
    },

    privacyPolicy() {
      return {
        copy: this.$t('account.footer.privacyPolicy.copy'),
        link: this.$t('account.footer.privacyPolicy.link'),
        target: this.$t('account.footer.privacyPolicy.target'),
        color: 'leading-7 mb-24 font-300 text-black-900 text-14 hover:text-black-600',
      }
    },

    formInitialValues() {
      let initialValues = {}
      const data = this.getRegisterData

      if (data?.status) {
        initialValues = {
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          specialty: data.specialty,
          npi: data.npi,
          email: data.email,
          phone: data.phone,
        }
      }

      if (this.isCRNA) initialValues.specialty = 'CRNA'

      return initialValues
    },

    validationSchema() {
      const schema = {
        specialty: this.$yup.string()
          .required(this.$t('identity.registerProfile.formValidation.specialty.required')),
        firstName: this.$yup.string()
          .required(this.$t('identity.registerProfile.formValidation.firstName.required'))
          .matches(/^([\u0041-\u005A\u0061-\u007A\u00A1-\u0FDA])+([\u0020\u0027\u002D\u0041-\u005A\u0061-\u007A\u00A1-\u0FDA])*$/, this.$t('identity.registerProfile.formValidation.firstName.format')),
        lastName: this.$yup.string()
          .required(this.$t('identity.registerProfile.formValidation.lastName.required'))
          .matches(/^([\u0041-\u005A\u0061-\u007A\u00A1-\u0FDA])+([\u0020\u0027\u002D\u0041-\u005A\u0061-\u007A\u00A1-\u0FDA])*$/, 'The last name field format is invalid'),
        email: this.$yup.string()
          .email(this.$t('identity.registerProfile.formValidation.email.format'))
          .required(this.$t('identity.registerProfile.formValidation.email.required')),
        phone: this.$yup.string()
          .required(this.$t('identity.registerProfile.formValidation.phone.required'))
          .matches(/^\d{10}$|^(\(\d{3}\) |\d{3}-)\d{3}-\d{4}$/, this.$t('identity.registerProfile.formValidation.phone.format')),
        smsCompliance: this.$yup.bool(),
      }

      if (this.showNpiField) {
        let npiNumberSchema = this.$yup.string()
        let invalidNpiMessage = this.$t('identity.registerProfile.formValidation.npi.format')

        if (this.npiRequired) {
          npiNumberSchema = npiNumberSchema.required(this.$t('identity.registerProfile.formValidation.npi.required'))
        } else {
          invalidNpiMessage = `${replaceLast(invalidNpiMessage, /\./, ',')} or leave this field blank.`
        }

        schema.npi = npiNumberSchema.npiNumber(invalidNpiMessage, !this.npiRequired)
      }

      return this.$toTypedSchema(this.$yup.object().shape(schema))
    },
  },

  methods: {
    ...mapActions('identity', ['createUser']),
    ...mapActions('system', ['checkConnectivity']),

    prepareFormData(values) {
      const data = {
        ...values,
        middleName: values.middleName || '',
        npi: values.npi || '',
        phone: values.phone.replace(/\D/g, ''),
        profession: this.profession,
        specialty: values.specialty,
        smsCompliance: true,
      }

      const webTrackingSource = this.getWebTracking('source')

      if (webTrackingSource) data.webTrackingSource = webTrackingSource?.value

      return data
    },

    async submitForm(values) {
      const data = this.prepareFormData(values)

      try {
        await this.createUser(data)

        this.$router.push({ path: '/register/check-email' })
      } catch (err) {
        const { response, message } = err

        // ACTION
        if (!response || !response.status) {
          // No response, usually "Network Error", keep on this page
          this.captureLogRocketMessage(
            response,
            message,
            'Registration: Network Error or no response'
          )

          this.checkConnectivity().then(() => {
            if (this.isOnline) {
              this.displayInlineErrorMessage()
              return
            }

            this.formMessageType = 'error'
            this.formMessage = this.$t('identity.login.service.errors.offline')
          })
        } else if (response.status === 409) {
          // store data for future use until this becomes successful
          // {"errorCode":409,"message":"User already exists","data":{}}
          // User already exists forward to another page
          // ASKBA: Do we clear the data or keep it since the user already exists
          this.$router.push({ path: '/register/existing' })
        } else if (response.status === 412) {
          // brand migration candidate show messaging to have them login with existing credentials.
          this.$router.push({
            name: 'register-migrate',
            params: { offBrandTitle: response.data.data },
          })
        } else if (response.status === 503) {
          this.captureLogRocketMessage(
            response,
            message,
            'Registration: Retry Attempts Failed'
          )
          this.displayInlineErrorMessage(this.$t('identity.registerProfile.service.errors.temporarilyUnavailable'))
        } else {
          // Unknown Error keep on this page
          this.captureLogRocketMessage(
            response,
            message,
            'Registration: Unexpected response'
          )
          this.displayInlineErrorMessage()
        }
      }
    },

    clearUIFormMessage() {
      this.formMessage = ''
    },

    displayInlineErrorMessage(message) {
      if (!this.isOnline) return

      this.formMessageType = 'error'
      this.formMessage = message || this.$t('identity.registerProfile.service.errors.unknown')
    },

    captureLogRocketMessage(response, errorMessage, message) {
      LogRocket.captureMessage(message, {
        tags: { errorType: 'registration' },
        extra: { response, errorMessage },
      })
    },
  },
}
