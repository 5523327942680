export default (str, pattern, replacement) => {
  const regex = new RegExp(pattern, 'g')
  const matches = [...str.matchAll(regex)]

  if (matches.length > 0) {
    const lastMatch = matches[matches.length - 1]
    const lastIndex = lastMatch.index

    return str.slice(0, lastIndex) + replacement + str.slice(lastIndex + lastMatch[0].length)
  }

  return str
}
